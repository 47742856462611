













































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { formatAsRub, numberWithSpaces, roundToDecimals, smartToFixed } from '@/common/misc';
// import Switches from 'vue-switches';
import ChannelTypeSelect from '@/components/elements/ChannelTypeSelect.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { channelTypes } from '@/common/config';
import CollapsibleUEC from '@/components/elements/CollapsibleUEC.vue';
import { ChannelModel, ChannelStageModel } from '@/common/classes/models';
import ChannelFields from '@/components/constructor-channel-tab/ChannelFields.vue';
import DropdownMenu from '@/components/elements/DropdownMenu.vue';
import channelManager from '@/common/classes/channel.manager';

export default Vue.extend({
    name: 'channel',
    props: {
        channel: {type: ChannelModel}
    },
    mixins: [MiscMixin],
    mounted(){
        if( this.channels.length == 1 ){
            this.targetFraction = 100;
        }
    },
    data(){
        return{
            formatAsRub,
            smartToFixed,
            numberWithSpaces,
            foldChannel: false,
            targetFraction: roundToDecimals( this.channel.targetFraction * 100, 2 ),
            // this.channel.targetMonthlySales / channelManager.getTotalTargetMonthlySales() * 100
        }
    },
    components: {
        // Switches,
        ChannelTypeSelect,
        CollapsibleUEC,
        ChannelFields,
        DropdownMenu,
    },
    watch: {
        channelName(newName){
            const channelNames = (this.channels as ChannelModel[]).map(({name})=>name);
            const isDuplicate = channelNames.filter((name)=>name==newName).length > 1;
            const input = this.$refs.chanNameInput as HTMLInputElement;
            if(isDuplicate){
                input.setCustomValidity('Название канала повторяется')
                //const uniqueName = getUniqueName(newName,channelNames);
                //this.$set(this.channel,'name',uniqueName);
            }else{
                input.setCustomValidity('');
            }
        },
        targetFraction(value){
            const {validity} = this.targetFractionInput;
            if(validity.rangeOverflow) this.$emit('fraction-over-max');
            const isValid = validity.valid || (validity.rangeOverflow && !validity.stepMismatch);
            if(isValid){
                // console.log({value,name:this.channel.name})
                const fraction = roundToDecimals( value / 100, 4 );
                this.$set( this.channel, 'targetFraction', fraction);
            }
        },
        actualTargetFraction(value){
            if(this.targetFractionInput.validity.valid)
                this.targetFraction = roundToDecimals( value * 100, 2 );
        },
        channelFractions(fractions:number[],oldFractions:number[]){
            if(!this.isLastChannel) return;
            const isAddedNewChannels = fractions.length == oldFractions.length;
            const isLastFractionSame = fractions[fractions.length-1] != oldFractions[oldFractions.length-1];
            if(!isAddedNewChannels && isLastFractionSame && fractions.length != 1) return;
            const {remainingTargetFractionWithoutCurrent} = this;
            this.targetFraction = roundToDecimals( remainingTargetFractionWithoutCurrent * 100, 2 );
        },
        channels(newArr:ChannelModel[], oldArr:ChannelModel[]){
            if(newArr.length == 1) this.$forceUpdate();
        },
    },
    methods: {
        async onChannelTabInactive(){
            if(this.isLastChannel || this.targetFractionInput.validity.valid) return;
            await Vue.nextTick();
            this.targetFraction = 0;
            this.channel.targetFraction = 0;
        },
        onFractionOverMax(channel:ChannelModel){
            if(this.isLastChannel){
                const {remainingTargetFractionWithoutCurrent,targetFractionInput} = this;
                // console.log({remainingTargetFractionWithoutCurrent,fractions:this.channelFractions})
                this.targetFraction = roundToDecimals( remainingTargetFractionWithoutCurrent * 100, 2 );
            }
        },
        onTypeSelect(e:any):void{
            const newVal = parseInt( e.target.value );
            const oldVal = this.channel.channelType;
            this.$set(this.channel, 'channelType', newVal);

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            const onClick = () => this.$modal.hide('dialog');
            const setStages = () => ChannelModel.setStagesFromPreset(this.channel,channelTypes[newVal]);

            if(this.channel.wasChanged(oldVal)){
                this.$modal.show('dialog',{
                    title: 'Канал был изменён',
                    text: 'Выберите действие',
                    buttons: [
                        {title:'Оставить все значения, поменять только тип',handler(){ onClick(); }},
                        {title:'Установить стандартные значения',handler(){ 
                            setStages();
                            onClick(); 
                        }},
                        {title:'Отмена',handler: () => { 
                            this.$set(self.channel, 'channelType', oldVal); 
                            onClick(); 
                        }},
                    ]
                })
            }else setStages();
        },
        addStage(this:any,stage:ChannelStageModel,stageIndex:number,below:boolean):void{
            // console.log('Adding stage '+(below?'below':'on top')+' of '+stage.name+`(${stageIndex})`)
            const stages:ChannelStageModel[] = this.channel.stages;
            const index = stageIndex+(below ? 1 : 0);
            const newStage = new ChannelStageModel();
            const spliced = stages.splice(index);
            this.$set(this.channel,'stages',[...stages,newStage,...spliced]);
            this.$forceUpdate();
        },
        deleteStage(this:any,stageIndex:number):void{
            // console.log('deleteing '+stageIndex);
            const stages:ChannelStageModel[] = this.channel.stages;
            // const index = stages.indexOf(stage);
            stages.splice(stageIndex,1);
        },
        funnelWidth(index:number){
            const offset = 10
            const totalStages = this.channel.stages.length;
            const defaultStep = 100/totalStages
            if(index==0)return 100-offset;
            return Math.max( defaultStep * Math.abs( totalStages - index ) - offset, 4)
        },
        funnelAlpha(index:number):number{
            return Math.max( Math.min(this.funnelWidth(index) / 100, 0.9), 0.1);
        },
    },
    computed: {
        channelFractions():number[]{ return (this.channels as ChannelModel[]).map(({targetFraction})=>targetFraction); },
        stages():ChannelStageModel[]{ return this.channel.stages || []; },
        cac():number{ return this.channel.cac() || 0; },
        channelName():string{ return this.channel.name; },
        channelType():number{ return this.channel.channelType; },
        singleSaleCost():number{ return this.channel.singleSaleCost(); },
        monthChannelExpense():number{ return this.channel.monthChannelExpense() || 0; },
        channelIndex():number{ return this.channels.indexOf(this.channel); },
        isLastChannel():boolean{ return this.channelIndex == this.channels.length - 1 },
        targetFractionInput():HTMLInputElement{ return this.$refs.targetFractionInput as HTMLInputElement; },
        maxTargetFraction:{
            get():number{
                const totalTargetFraction = (this.channels as ChannelModel[]).reduce((acc,{targetFraction})=>acc+targetFraction,0);
                return Math.min(100, (1 - (totalTargetFraction - this.channel.targetFraction)) * 100 + 0.01 );
            },
            cache: false
        },
        stagesConverted():number[]{
            return this.channel.stages.map((stage)=>this.channel.calcConverted(stage));
        },
        actualTargetFraction: {
            get():number{
                return this.channel.targetFraction;
            },
            cache: false
        },
        remainingTargetFractionWithoutCurrent: {
            get():number{
                const totalFractions = this.channelFractions.reduce((acc,cur)=>acc+cur);
                const totalFractionsWithoutCurrent = totalFractions - this.channel.targetFraction;
                const remainingFraction = Math.max(0,1 - totalFractionsWithoutCurrent);
                return roundToDecimals( remainingFraction, 4 );
            },
            cache: false
        },
        ...mapGetters(['product','channels','isLoadingProject'])
    }
})
