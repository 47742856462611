var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-tab ym-show-content",staticStyle:{"width":"100%"}},[_c('draggable',{attrs:{"group":"channels","options":{
        handle: '.my-handle',
        animation:250,
        forceFallback:true,
        chosenClass:'chosen-channel',
        dragClass: 'dragging-channel',
        ghostClass: 'ghost-channel',
    }},on:{"start":function($event){_vm.drag=true},"end":function($event){_vm.drag=false}},model:{value:(_vm.channelsArray),callback:function ($$v) {_vm.channelsArray=$$v},expression:"channelsArray"}},_vm._l((_vm.channelsArray),function(channel,index){return _c('channel',{key:index,ref:"channel",refInFor:true,attrs:{"channel":channel},on:{"fraction-over-max":function($event){_vm.$refs['channel'].forEach(function (c){ return c.onFractionOverMax(channel); })},"create-channel":_vm.createChannel,"duplicate-channel":_vm.duplicateChannel,"delete-channel":_vm.deleteChannel}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }