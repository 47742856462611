





























































import Vue from 'vue';
import Channel from '@/components/constructor-channel-tab/Channel.vue';
import { mapGetters, mapState } from 'vuex';
import { ChannelModel } from '@/common/classes/models';
import { SET_CHANNELS } from '@/store/mutations';
// import ChannelFields from '@/components/constructor-channel-tab/ChannelFields.vue';
import Draggable from 'vuedraggable';
// import Switches from 'vue-switches';
import MiscMixin from '../mixins/MiscMixin.vue';

export default Vue.extend({
    name: 'channelTab',
    props: {
        channelTabIsActive: {type:Boolean,default: true}
    },
    watch: {
        channelTabIsActive(isActive){
            if(!isActive)
                (this.$refs.channel as any[]).forEach((vueChannel)=>vueChannel.onChannelTabInactive());
        },
    },
    components: {
        Channel,
        // ChannelFields,
        Draggable,
        // Switches,
    },
    mixins: [MiscMixin],
    methods: {
        createChannel(fromChannel:ChannelModel):void{
            //clone to avoid fold bugs
            this.channels.push( new ChannelModel().clone() );
        },
        duplicateChannel(fromChannel:ChannelModel):void{
            this.channels.push( fromChannel.clone() );
        },
        deleteChannel(fromChannel:ChannelModel):void{
            const filtered = this.channels.filter((chan:ChannelModel)=> chan != fromChannel );
            if(filtered.length == this.channels.length) {console.warn({msg:'Unable to delete channel from store',fromChannel,channels:this.channels})}
            this.$store.commit(SET_CHANNELS, filtered);
        }
    },
    computed: {
        cDrag: {
            get(this:any):any{
                return this.drag;
            },
            cache: false
        },
        deleteChannelList: {
            get():ChannelModel[]{ return []; },
            set(delChannels:ChannelModel[]):void{
                const delChannel = delChannels[0];
                const allChannels = this.channels;
                if(allChannels.length < 2){
                    allChannels.push(delChannel);
                }
            },
        },
        channelsArray: {
            get():ChannelModel[]{
                return this.channels
            },
            set(channels:ChannelModel[]){
                this.$store.commit(SET_CHANNELS,channels);
            },
        },
        ...mapState(['channels'])
    },
})
