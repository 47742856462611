




















import Vue from 'vue';
import { mapGetters } from 'vuex';

import '@/assets/css/constructor.less';
export default Vue.extend({
    name: 'channelFields',
})
