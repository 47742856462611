




























import Vue from 'vue'
export default Vue.extend({
    name: 'collapsibleUEC',
    watch: {
        dFold(fold:boolean){ this.$emit(fold?'folded':'unfolded') }
    },
    data(){
        return{
            dFold: this.fold
        }
    },
    props: {
        fold:{
            type:Boolean,
            default:false,
        },
        color: {
            type:String, 
            default: '#FFFFF',
        },
        //TODO: Use library https://stackoverflow.com/a/12043228
        isLightColor: {
            type:Boolean,
            default: true,
        }
    },
})
